import React from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';

import Index from './AccountBook/Index';


export default function AccountBook() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Index />} />
            </Routes>
        </>

    );
}