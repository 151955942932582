import React, { useState, useEffect } from 'react';
import { Grid, Typography, Backdrop, TextField, CircularProgress, Box, Button, MenuItem, Select, FormHelperText, FormControl } from '@mui/material';
import { useNavigate } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { getToken, IsLogin } from '../auth';
import { Link } from 'react-router-dom';

import { getStaffTypeList, addStaff, checkStaffExist } from "../../API/api.js";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));


export default function Add() {
    const [loadico, setloadico] = useState(false)
    const classes = useStyles();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        StaffName: '',
        StaffTypeID: 0,
        StaffNote: '',
        IsShow: 1
    });

    const handleStaffName = event => {
        setValues({ ...values, StaffName: event.target.value });
    };

    const handleStaffTypeID = event => {
        setValues({ ...values, StaffTypeID: event.target.value });
    };

    const handleStaffNote = event => {
        setValues({ ...values, StaffNote: event.target.value });
    };

    const handleIsShow = event => {
        setValues({ ...values, IsShow: event.target.value });
    };


    const [StaffTypeListData, setStaffTypeListData] = useState([])
    const getList = () => {

        getStaffTypeList()
            .then(res => {
                const valuesArray = res.data;
                setStaffTypeListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }



    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();

        checkStaffExist(values.StaffName)
            .then(res => {
                if (res.data) {
                    alert('新增服務人員已存在');
                } else {
                    if (values.StaffName.length > 0) {
                        setloadico(true);
                        addStaff(values, getToken())
                            .then(res => {
                                if (res.data) {
                                    navigate('/Admin/Staff');
                                }
                            })
                            .catch(err => {
                                console.log('err', err);
                            })
                            .finally(() => setloadico(false))
                    }
                }
            })
            .catch(err => {
                setloadico(false)
                console.log('err', err);
            })


    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Typography variant="h4" gutterBottom>
                新增服務人員
            </Typography>
            <Box>
                <form autoComplete="off" onSubmit={submit}>
                    <Typography variant="h6" gutterBottom>
                        請輸入下列資訊
                    </Typography>
                    <Box sx={{ m: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    required
                                    id="StaffName"
                                    name="StaffName"
                                    label="服務人員"
                                    onChange={handleStaffName}
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <FormControl required>
                                    <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>選擇服務人員類別：</FormHelperText>
                                    <Select
                                        value={values.StaffTypeID}
                                        onChange={handleStaffTypeID}
                                    >
                                        <MenuItem key={-1} value={0}>{""}</MenuItem>
                                        {
                                            
                                            StaffTypeListData.map(
                                                (childItem, chiIndex) => {
                                                    return (
                                                        <MenuItem key={chiIndex} value={childItem.Id}>{childItem.StaffTypeName}</MenuItem>
                                                    )
                                                }
                                            )
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <TextField
                                    required
                                    id="StaffNote"
                                    name="StaffNote"
                                    label="服務人員備註"
                                    onChange={handleStaffNote}
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>是否顯示在日報EXCEL選單：</FormHelperText>
                                <FormControl>

                                    <Select
                                        value={values.IsShow}
                                        onChange={handleIsShow}
                                    >
                                        <MenuItem value={1}>是</MenuItem>
                                        <MenuItem value={0}>否</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" size='large' type="submit" startIcon={<SaveIcon />}>
                            儲存
                        </Button>

                        <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/Admin/Staff"}>
                            <Button variant="contained" color="secondary" size='large' style={{ marginLeft: '8px' }} startIcon={<CancelIcon />}>
                                取消
                            </Button>
                        </Link>
                    </Box>
                </form>

            </Box>
        </>
    );
}