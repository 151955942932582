import React, { useState, useEffect } from 'react';
import { Grid, Typography, Backdrop, TextField, CircularProgress, Box, Button, MenuItem, Select, FormHelperText, FormControl } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import { getToken, IsLogin } from '../auth';
import { Link } from 'react-router-dom';

import { editStaff, getStaffTypeList, checkStaffExist, checkStaffExistInWorkRecord, getStaffByName, deleteStaff } from "../../API/api.js";


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '600px',
        },
        formControl: {
            margin: theme.spacing(1),

            minWidth: '600px',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));


export default function Edit() {
    const [loadico, setloadico] = useState(false)
    const classes = useStyles();
    const navigate = useNavigate();
    let { id } = useParams();

    const [values, setValues] = useState({
        StaffName: '',
        OLDStaffName: '',
        StaffTypeID: 0,
        StaffNote: '',
        IsShow: 1
    });

    const handleStaffName = event => {
        setValues({ ...values, StaffName: event.target.value });
    };

    const handleStaffTypeID = event => {
        setValues({ ...values, StaffTypeID: event.target.value });
    };

    const handleStaffNote = event => {
        setValues({ ...values, StaffNote: event.target.value });
    };

    const handleIsShow = event => {
        setValues({ ...values, IsShow: event.target.value });
    };


    const [StaffTypeListData, setStaffTypeListData] = useState([])

    const getList = () => {

        getStaffTypeList()
            .then(res => {
                const valuesArray = res.data;
                setStaffTypeListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }

    const delStaff = () => {

        checkStaffExistInWorkRecord(values.StaffName)
            .then(res => {
                if (res.data) {
                    alert('服務人員已存在歷史資料，無法刪除');
                } else {
                    var yes = window.confirm('你確定刪除' + values.StaffName + '資料？');

                    if (yes) {
                        if (values.StaffName) {
                            setloadico(true);
                            deleteStaff(values, getToken())
                                .then(res => {
                                    if (res.data) {
                                        navigate('/Admin/Staff');
                                    }
                                })
                                .catch(err => {
                                    console.log('err', err);
                                })
                                .finally(() => setloadico(false))
                        }
                    }
                }
            })
            .catch(err => {
                setloadico(false)
                console.log('err', err);
            })


       
    }


    const submit = e => {
        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();
        if (values.StaffName != values.OLDStaffName) {


            checkStaffExistInWorkRecord(values.OLDStaffName)
                .then(res => {
                    if (res.data) {
                        alert('服務人員已存在歷史資料，無法變更');
                    } else {
                        var yes = window.confirm('你確定更新' + values.StaffName + '資料？');

                        if (yes) {

                            checkStaffExist(values.StaffName)
                                .then(res => {
                                    if (res.data) {
                                        alert('服務人員已存在');
                                    } else {
                                        save();
                                    }
                                })
                                .catch(err => {
                                    setloadico(false)
                                    console.log('err', err);
                                })
                        }
                    }
                })
                .catch(err => {
                    setloadico(false)
                    console.log('err', err);
                })



        }
        else {
            save();
        }


    }

    const save = () => {
        if (values.StaffName) {
            setloadico(true);
            editStaff(values, getToken())
                .then(res => {
                    if (res.data) {
                        navigate('/Admin/Staff');
                    }
                })
                .catch(err => {
                    console.log('err', err);
                })
                .finally(() => setloadico(false))
        }
    };



    const loadDate = (id) => {
        setloadico(true);
        getStaffByName(id)
            .then(res => {
                setloadico(false)
                const result = res.data;
                if (id) {
                    setValues(
                        {
                            StaffName: result.StaffName,
                            OLDStaffName: result.StaffName,
                            StaffTypeID: result.StaffTypeID,
                            StaffNote: result.StaffNote,
                            IsShow: result.IsShow
                        }
                    );
                }


            })
            .catch(err => {
                console.log('err', err);
                setloadico(false)
            })
    }

    useEffect(() => {
        loadDate(id);
    }, [id]);

    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Typography variant="h4" gutterBottom>
                修改服務人員
            </Typography>
            <Box>
                <form autoComplete="off" onSubmit={submit}>
                    <Typography variant="h6" gutterBottom>
                        請輸入下列資訊
                    </Typography>
                    <Box sx={{ m: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    required
                                    id="StaffName"
                                    name="StaffName"
                                    label="服務人員"
                                    onChange={handleStaffName}
                                    value={values.StaffName}
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <FormControl required>
                                    <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>選擇服務人員類別：</FormHelperText>
                                    <Select
                                        value={values.StaffTypeID}
                                        onChange={handleStaffTypeID}
                                    >
                                        <MenuItem key={-1} value={0}>{""}</MenuItem>
                                        {

                                            StaffTypeListData.map(
                                                (childItem, chiIndex) => {
                                                    return (
                                                        <MenuItem key={chiIndex} value={childItem.Id}>{childItem.StaffTypeName}</MenuItem>
                                                    )
                                                }
                                            )
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <TextField
                                    required
                                    id="StaffNote"
                                    name="StaffNote"
                                    label="服務人員備註"
                                    value={values.StaffNote}
                                    onChange={handleStaffNote}
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>是否顯示在日報EXCEL選單：</FormHelperText>
                                <FormControl>

                                    <Select
                                        value={values.IsShow}
                                        onChange={handleIsShow}
                                    >
                                        <MenuItem value={1}>是</MenuItem>
                                        <MenuItem value={0}>否</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" size='large' type="submit" startIcon={<SaveIcon />}>
                            儲存
                        </Button>

                        <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/Admin/Staff"}>
                            <Button variant="contained" color="secondary" size='large' style={{ marginLeft: '8px' }} startIcon={<CancelIcon />}>
                                取消
                            </Button>
                        </Link>

                        <Button style={{ marginLeft: '8px' }} variant="contained" color="secondary" size="large" onClick={delStaff} startIcon={<DeleteForeverIcon />}>
                            刪除
                        </Button>
                    </Box>
                </form>

            </Box>
        </>
    );
}