import React from 'react';

import PieChart, {
    Legend,
    Series,
    Tooltip,
    Format,
    Label,
    Connector,
    Export,
} from 'devextreme-react/pie-chart';

//import { populationByRegions } from './DoughnutChartData.js';

class DoughnutChart extends React.Component {
    render() {
        return (
            <PieChart
                id="doughnut"
                sx={{ height: 640}}
                type="doughnut"
                title={this.props.title}
                palette="Soft Pastel"
                dataSource={this.props.dataSource}
            >
                <Series argumentField="region">
                    <Label visible={true} customizeText={this.formatLabel}>
                        <Connector visible={true} />
                    </Label>
                </Series>
                <Export enabled={true} />
                <Legend
                    margin={0}
                    horizontalAlignment="right"
                    verticalAlignment="top"
                />
                <Tooltip enabled={true} customizeTooltip={this.customizeTooltip}>
                   
                </Tooltip>
            </PieChart>
        );
    }

    formatLabel(arg) {
        return `${arg.valueText}節`;
    }

    customizeTooltip(arg) {
        return {
            text: `${arg.argumentText} ${arg.valueText}節 - ${(arg.percent * 100).toFixed(2)}%`,
        };
    }
}

export default DoughnutChart;