import React from 'react';
import { Routes, Route, Link, useNavigate }from 'react-router-dom';

import Index from './WorkRecord/Index';
import Detail from './WorkRecord/Detail';

export default function WorkRecord() {
    return (
        <>
            <Routes>
                <Route path='/' element={<Index />} />
                <Route path='Detail/:ReportDate' element={<Detail />} />
            </Routes>
        </>

    );
}