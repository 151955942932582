import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import DoughnutChart from './Doughnut';
import Pie from './PieChart';
import BarChart1 from './BarChart';
import { makeStyles } from '@material-ui/core/styles';
import { Chart1, Chart2, Chart3, Chart4 } from "../../API/api.js";
import { CircularProgress, Backdrop } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));


function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                
                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                border: '1px solid',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                p: 1,
                m: 1,
                borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}

Item.propTypes = {
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};

export default function Index() {

    const [loadico, setloadico] = useState(false)
    const classes = useStyles();
    const [ListData, getListData] = useState([])
    const [ListData2, getListData2] = useState([])
    const [ListData3, getListData3] = useState([])
    const [ListData4, getListData4] = useState([])

    const getList = () => {
        setloadico(true);
        Chart1()
            .then(res => {
                const valuesArray = res.data;
                getListData(valuesArray);
               
            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => setloadico(false))

    
    }

    const getList2 = () => {
        setloadico(true);
      

        Chart2()
            .then(res => {
                const valuesArray = res.data;
                getListData2(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => setloadico(false))
    }

    const getList3 = () => {
        setloadico(true);


        Chart3()
            .then(res => {
                const valuesArray = res.data;
                getListData3(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => setloadico(false))
    }

    const getList4 = () => {
        setloadico(true);


        Chart4()
            .then(res => {
                const valuesArray = res.data;
                getListData4(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => setloadico(false))
    }


    useEffect(() => {
        getList();
        getList2();
        getList3();
        getList4();
    }, []);

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>

            <div style={{ width: '100%' }}>
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'flex' }, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                    <Item sx={{ width: { xs: 1, sm: 1, md: 1 / 2 } }}><DoughnutChart dataSource={ListData} title="本月小姐服務累積節數" /></Item>
                    <Item sx={{ width: { xs: 1, sm: 1, md: 1 / 2 } }}><Pie dataSource={ListData2} title="本月公關服務累積金額"/></Item>
                </Box>
                <Box sx={{ display: { xs: 'block', sm: 'block', md: 'flex' }, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                    <Item sx={{ width: { xs: 1, sm: 1, md: 1 / 2 } }}><DoughnutChart dataSource={ListData3} title="上個月小姐服務累積節數"/></Item>
                    <Item sx={{ width: { xs: 1, sm: 1, md: 1 / 2 } }}><Pie dataSource={ListData4} title="上個月公關服務累積金額"/></Item>
                </Box>
            </div>

        </>
    );
}