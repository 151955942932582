import React from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';

import Index from './Staff/Index';
import Add from './Staff/Add';
import Edit from './Staff/Edit';

export default function Staff() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path='Add' element={<Add />} />
                <Route path='Edit/:id' element={<Edit />} />
            </Routes>
        </>

    );
}