import React from 'react';

import PieChart, {
    Series,
    Label,
    Connector,
    SmallValuesGrouping,
    Legend,
    Export,
} from 'devextreme-react/pie-chart';

//import { dataSource } from './PieChartData.js';


class Pie extends React.Component {
    render() {
        return (
            <PieChart
                id="pie"
                dataSource={this.props.dataSource}
                sx={{ height: 640 }}
                palette="Bright"
                title={this.props.title}

            >
                <Series
                    argumentField="region"
                    valueField="val"
                >
                    <Label visible={true} customizeText={this.formatLabel} >
                        <Connector visible={true} width={0.5} />
                    </Label>
                    <SmallValuesGrouping threshold={4.5} mode="smallValueThreshold" />
                </Series>
                <Legend horizontalAlignment="center" verticalAlignment="bottom" />
                <Export enabled={true} />
            </PieChart>
        );
    }

    formatLabel(arg) {
        return `${arg.argumentText}: ${arg.valueText}元`;
    }

}

export default Pie;
