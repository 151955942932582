import axios from 'axios';


//API的 ip 位址寫在 index.html
let webURL = window.webURL;

const systemRequest = axios.create({
    baseURL: webURL+'/api/System/'
});

const authRequest = axios.create({
    baseURL: webURL +'/api/Auth/'
});

const reportRequest = axios.create({
    baseURL: webURL + '/api/Report/'
});

//登入相關的api

//Username
//Password
export const Login = data => authRequest.post('/Login', data);
export const getAllUser = (token) => authRequest.get('/GetAllUser', { headers: { 'Authorization': `Bearer ${token}` } });
export const setUser = (data, token) => authRequest.post('/SetUser', data, { headers: { 'Authorization': `Bearer ${token}` } });

//StaffType
export const checkStaffTypeExist = (StaffTypeName) => systemRequest.get('/CheckStaffTypeExist?StaffTypeName=' + StaffTypeName);
export const addStaffType = (data, token) => systemRequest.post('/AddStaffType', data, { headers: { 'Authorization': `Bearer ${token}` } });
export const editStaffType = (data, token) => systemRequest.post('/EditStaffType', data, { headers: { 'Authorization': `Bearer ${token}` } });
export const getStaffTypeGridList = (data) => systemRequest.post('/GetStaffTypeGridList', data);
export const getStaffTypeById = data => systemRequest.get('/GetStaffTypeById?Id=' + data);
export const deleteStaffType = (data, token) => systemRequest.post('/DeleteStaffType', data, { headers: { 'Authorization': `Bearer ${token}` } });
export const getStaffTypeList = () => systemRequest.get('/GetStaffTypeList');

//Staff
export const checkStaffExist = (StaffName) => systemRequest.get('/CheckStaffExist?StaffName=' + StaffName);
export const checkStaffExistInWorkRecord = (StaffName) => systemRequest.get('/CheckStaffExistInWorkRecord?StaffName=' + StaffName);


export const addStaff = (data, token) => systemRequest.post('/AddStaff', data, { headers: { 'Authorization': `Bearer ${token}` } });
export const editStaff = (data, token) => systemRequest.post('/EditStaff', data, { headers: { 'Authorization': `Bearer ${token}` } });
export const getStaffGridList = (data) => systemRequest.post('/GetStaffGridList', data);
export const getStaffByName = data => systemRequest.get('/GetStaffByName?staffName=' + data);
export const deleteStaff = (data, token) => systemRequest.post('/DeleteStaff', data, { headers: { 'Authorization': `Bearer ${token}` } });

//ServiceItem
export const checkServiceItemExist = (ServiceItemName) => systemRequest.get('/CheckServiceItemExist?ServiceItemName=' + ServiceItemName);
export const addServiceItem = (data, token) => systemRequest.post('/AddServiceItem', data, { headers: { 'Authorization': `Bearer ${token}` } });
export const editServiceItem = (data, token) => systemRequest.post('/EditServiceItem', data, { headers: { 'Authorization': `Bearer ${token}` } });
export const getServiceItemGridList = (data) => systemRequest.post('/GetServiceItemGridList', data);
export const getServiceItemById = data => systemRequest.get('/GetServiceItemById?Id=' + data);
export const deleteServiceItem = (data, token) => systemRequest.post('/DeleteServiceItem', data, { headers: { 'Authorization': `Bearer ${token}` } });
export const checkServiceItemExistInWorkRecord = (ServiceItemName) => systemRequest.get('/checkServiceItemExistInWorkRecord?ServiceItemName=' + ServiceItemName);


//DownloadReport
export const DownloadReport = (token) => {
   
    return axios({
        method: 'get',
        url: `${webURL}/api/Report/DownloadReport/`,
        headers: { 'Authorization': `Bearer ${token}` },
        responseType: 'blob', // important
    })
};

export const DownloadMonthReport = (token, YearMonth) => {
    return axios({
        method: 'get',
        url: `${webURL}/api/Report/DownloadMonthReport?YearMonth=` + YearMonth,
        headers: { 'Authorization': `Bearer ${token}` },
        responseType: 'blob', // important
    })
};

//WorkRecord
export const getWorkRecordGridList = (data) => reportRequest.post('/GetWorkRecordGridList', data);
export const getWorkRecordDetailGridList = (data) => reportRequest.post('/GetWorkRecordDetailGridList', data);
export const deleteWorkRecord = (data, token) => reportRequest.post('/DeleteWorkRecord', data, { headers: { 'Authorization': `Bearer ${token}` } });
export const getAccountBookGridList = (data) => reportRequest.post('/GetAccountBookGridList', data);

//Report
export const CheckUploadReportFile = (data, token) => reportRequest.post('/CheckUploadReportFile', data, { headers: { 'Authorization': `Bearer ${token}` }, responseType: 'blob' });

export const Chart1 = (data, token) => reportRequest.post('/GetChart1', data);
export const Chart2 = (data, token) => reportRequest.post('/GetChart2', data);
export const Chart3 = (data, token) => reportRequest.post('/GetChart3', data);
export const Chart4 = (data, token) => reportRequest.post('/GetChart4', data);