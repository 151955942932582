import React, { useState, useEffect } from 'react';
import { Grid, Typography, Backdrop, TextField, CircularProgress, Box, Button, Divider } from '@mui/material';
import { useNavigate } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { getToken, IsLogin } from '../auth';
import { Link } from 'react-router-dom';

import { addStaffType, checkStaffTypeExist } from "../../API/api.js";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));


export default function Add() {
    const [loadico, setloadico] = useState(false)
    const classes = useStyles();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        StaffTypeName: ''
    });

    const handleStaffTypeName = event => {
        setValues({ ...values, StaffTypeName: event.target.value });
    };

    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();

        checkStaffTypeExist(values.StaffTypeName)
            .then(res => {
                if (res.data) {
                    alert('新增服務人員類別已存在');
                } else {
                    if (values.StaffTypeName.length > 0) {
                        setloadico(true);
                        addStaffType(values, getToken())
                            .then(res => {
                                if (res.data) {
                                    navigate('/Admin/StaffType');
                                }
                            })
                            .catch(err => {
                                console.log('err', err);
                            })
                            .finally(() => setloadico(false))
                    }
                }
            })
            .catch(err => {
                setloadico(false)
                console.log('err', err);
            })


    }

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Typography variant="h4" gutterBottom>
                新增服務人員類別
            </Typography>
            <Box>
                <form autoComplete="off" onSubmit={submit}>
                    <Typography variant="h6" gutterBottom>
                        請輸入下列資訊
                    </Typography>
                    <Box sx={{ m: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    required
                                    id="StaffTypeName"
                                    name="StaffTypeName"
                                    label="服務人員類別"
                                    onChange={handleStaffTypeName}
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                />
                            </Grid>

                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" size='large' type="submit" startIcon={<SaveIcon />}>
                            儲存
                        </Button>

                        <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/Admin/StaffType"}>
                            <Button variant="contained" color="secondary" size='large' style={{ marginLeft: '8px' }} startIcon={<CancelIcon />}>
                                取消
                            </Button>
                        </Link>
                    </Box>
                </form>

            </Box>
        </>
    );
}