import React, { useState, useEffect } from 'react';
import { Grid, Typography, Backdrop, TextField, CircularProgress, Box, Button, Divider } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { makeStyles } from '@material-ui/core/styles';
import { getToken, IsLogin } from '../auth';
import { Link } from 'react-router-dom';

import { editStaffType, checkStaffTypeExist, getStaffTypeById, deleteStaffType } from "../../API/api.js";


const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '600px',
        },
        formControl: {
            margin: theme.spacing(1),

            minWidth: '600px',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));


export default function Edit() {
    const [loadico, setloadico] = useState(false)
    const classes = useStyles();
    const navigate = useNavigate();
    let { id } = useParams();

    const [values, setValues] = useState({
        ID: 0,
        StaffTypeName: '',
        OLDStaffTypeName: ''
    });


    const handleStaffTypeName = event => {
        setValues({ ...values, StaffTypeName: event.target.value });
    };

    const delStaffType = () => {
        var yes = window.confirm('你確定刪除' + values.StaffTypeName + '資料？');

        if (yes) {
            if (values.ID) {
                setloadico(true);
                deleteStaffType(values, getToken())
                    .then(res => {
                        if (res.data) {
                            navigate('/Admin/StaffType');
                        }
                    })
                    .catch(err => {
                        console.log('err', err);
                    })
                    .finally(() => setloadico(false))
            }
        }
    }

    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();
        if (values.StaffTypeName != values.OLDStaffTypeName) {
            checkStaffTypeExist(values.StaffTypeName)
                .then(res => {
                    if (res.data) {
                        alert('服務人員類別已存在');
                    } else {
                        save();
                    }
                })
                .catch(err => {
                    setloadico(false)
                    console.log('err', err);
                })
        }
        else {
            save();
        }

        const save = () => {
            if (values.ID) {
                setloadico(true);
                editStaffType(values, getToken())
                    .then(res => {
                        if (res.data) {
                            navigate('/Admin/StaffType');
                        }
                    })
                    .catch(err => {
                        console.log('err', err);
                    })
                    .finally(() => setloadico(false))
            }
        };

    }

    const loadDate = (id) => {
        setloadico(true);
        getStaffTypeById(id)
            .then(res => {
                setloadico(false)
                const result = res.data;
                if (id) {
                    setValues(
                        {
                            ID: result.ID,
                            StaffTypeName: result.StaffTypeName,
                            OLDStaffTypeName: result.StaffTypeName
                        }
                    );
                }


            })
            .catch(err => {
                console.log('err', err);
                setloadico(false)
            })
    }

    useEffect(() => {
        loadDate(id);
    }, [id]);

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Typography variant="h4" gutterBottom>
                修改服務人員類別
            </Typography>
            <Box>
                <form autoComplete="off" onSubmit={submit}>
                    <Typography variant="h6" gutterBottom>
                        請輸入下列資訊
                    </Typography>
                    <Box sx={{ m: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    required
                                    id="StaffTypeName"
                                    name="StaffTypeName"
                                    label="服務人員類別"
                                    onChange={handleStaffTypeName}
                                    value={values.StaffTypeName}
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                />
                            </Grid>

                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" size='large' type="submit" startIcon={<SaveIcon />}>
                            儲存
                        </Button>

                        <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/Admin/StaffType"}>
                            <Button variant="contained" color="secondary" size='large' style={{ marginLeft: '8px' }} startIcon={<CancelIcon />}>
                                取消
                            </Button>
                        </Link>

                        <Button style={{ marginLeft: '8px' }} variant="contained" color="secondary" size="large" onClick={delStaffType} startIcon={<DeleteForeverIcon />}>
                            刪除
                        </Button>
                    </Box>
                </form>

            </Box>
        </>
    );
}