export const dataSource = [{
    state: '2021年01月',
    家家: 12.95,
    拉拉: 2.85,
    LV: 45.56,
}, {
    state: '2021年02月',
    家家: 4.95,
    拉拉: 12.85,
    LV: 45.56,
}, {
    state: '2021年03月',
    家家: 24.95,
    拉拉: 12.85,
    LV: 45.56,
}, {
    state: '2021年04月',
    家家: 4.95,
    拉拉: 2.85,
    LV: 45.56,
}, {
    state: '2021年05月',
    家家: 4.95,
    拉拉: 2.85,
    LV: 45.56,
}, {
    state: '2021年07月',
    家家: 12.94,
    拉拉: 17.66,
    LV: 4.13,
}, {
    state: '2021年07月',
    拉拉: 50.66,
    LV: 42.13,
}, {
    state: '2021年08月',
    家家: 50.66,
    LV: 42.13,
}, {
    state: '2021年09月',
    家家: 50.66,
    育萱: 42.13,
}, {
    state: '2021年10月',
    育萱: 42.13,
}, {
    state: '2021年11月',
    育萱: 42.13,
}, {
    state: '2021年12月',
    育萱: 22.13,
    家家: 30.66,
    LV: 42.13,
}];
