import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Login from './components/pages/Login';
import Admin from './components/pages/Admin';
import { TransitionGroup, CSSTransition } from "react-transition-group";

const AnimatedSwitch = () => {
    const location = useLocation();
    return (
        //�}�ʵeuseEffect�|�]�G��
        //<TransitionGroup>
        //    <CSSTransition key={location.key} classNames="slide" timeout={300} unmountOnExit>
                <Routes>
                    <Route path='/' element={<Login />} />
                    <Route path='/Admin/*' element={<Admin />} />
                    <Route path='/Login' element={<Login />} />
                </Routes>
        //    </CSSTransition>
        //</TransitionGroup>
    );
};

ReactDOM.render(

    <BrowserRouter>
        <AnimatedSwitch />
    </BrowserRouter>

    , document.getElementById('root'));