import React from 'react';

import {
    Chart, Series, CommonSeriesSettings, Legend, Export,
} from 'devextreme-react/chart';
import { dataSource } from './BarChartData.js';

class BarChart1 extends React.Component {
    render() {
        return (
            <Chart
                id="chart"
                palette="Soft"
                sx={{ height: 640 }}
                title="2021每月公關業績圖 (非正式資料僅演示用)"
                dataSource={dataSource}
            >
                <CommonSeriesSettings
                    argumentField="state"
                    type="bar"
                    ignoreEmptyPoints={true}
                />
                <Series valueField="育萱" name="育萱(公關)" />
                <Series valueField="家家" name="家家(公關)" />
                <Series valueField="LV" name="LV(公關)" />
                <Series valueField="拉拉" name="拉拉(公關)" />
                <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                <Export enabled={true} />
            </Chart>
        );
    }
}

export default BarChart1;