import React from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';

import Index from './StaffType/Index';
import Add from './StaffType/Add';
import Edit from './StaffType/Edit';

export default function StaffType() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path='Add' element={<Add />} />
                <Route path='Edit/:id' element={<Edit />} />
            </Routes>
        </>

    );
}