import { isExpired , decodeToken } from "react-jwt";

export function getToken() {
    let token = localStorage.getItem('RegentSPAToken');
    if (token === null || token === undefined) {
        token = "";
    }
    return token;
}

export function RemoveToken() {
    localStorage.removeItem('RegentSPAToken');
}

export function Logout() {
    RemoveToken();
}

export function TokenExpired() {
    if (getToken() !== "") {
        return isExpired(getToken());
    } else {
        return true;
    }
}

export function IsLogin() {
    return !TokenExpired();
}





