import React from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';

import Index from './ServiceItem/Index';
import Add from './ServiceItem/Add';
import Edit from './ServiceItem/Edit';

export default function ServiceItem() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path='Add' element={<Add />} />
                <Route path='Edit/:id' element={<Edit />} />
            </Routes>
        </>

    );
}