import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography, Box, Button, Stack, IconButton } from '@mui/material';
import moment from "moment"
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';

import { DownloadReport, CheckUploadReportFile, getWorkRecordDetailGridList } from "../../API/api.js";
import { getToken } from '../auth';

//ID,ReportDate,Room,EntryTime,Customer,ServiceManager,ServiceStaff,ServiceItem,ServiceStartTime,ServiceEndTime,ServiceSection,ServiceFee,WorkNote,Creator,CreateDate
const columns = [
    { id: 'ReportDate', label: '報表日期', minWidth: 70 },
    { id: 'Room', label: '廂房', minWidth: 70 },
    { id: 'EntryTime', label: '帶客時間', minWidth: 70, type: 'time' },
    { id: 'Customer', label: '客人姓名', minWidth: 70 },
    { id: 'ServiceManager', label: '服務經理', minWidth: 70 },
    { id: 'ServiceStaff', label: '服務人員', minWidth: 70 },
    { id: 'ServiceItem', label: '服務項目', minWidth: 70 },
    { id: 'ServiceStartTime', label: '服務時間', minWidth: 70, type: 'time' },
    { id: 'ServiceEndTime', label: '送客時間', minWidth: 70, type: 'time' },
    { id: 'ServiceSection', label: '節數', minWidth: 70 },
    { id: 'ServiceFee', label: '費用', minWidth: 70 },
    { id: 'WorkNote', label: '備註', minWidth: 100 },
    { id: 'Creator', label: '上傳人員', minWidth: 70 },
    { id: 'CreateDate', label: '新增日期', minWidth: 70, type: 'datetime' },
];

export default function Detail() {
    let { ReportDate } = useParams();
    const [page, setPage] = useState(0);
    const [ListData, setListData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginationTotal, setPaginationTotal] = useState(0)
    const [loadico, setloadico] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getList = (pagenumber, pagesize, ReportDate) => {
        setloadico(true);
        getWorkRecordDetailGridList({ pagenumber: pagenumber + 1, pagesize: pagesize, reportDate: ReportDate })
            .then(res => {
                const valuesArray = res.data;
                setListData(valuesArray);
                setPaginationTotal(valuesArray[0].TOTAL_ROW_COUNT);
            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => setloadico(false))
    }

    const Input = styled('input')({
        display: 'none',
    });



    useEffect(() => {
        getList(page, rowsPerPage, ReportDate);
    }, [page, rowsPerPage]);


    const downloadReport = () => {
        DownloadReport(getToken()).then((response) => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', '工作紀錄表.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();


        });
    }

    const uploadReport = (event) => {

        const formData = new FormData();
        formData.append('File1', event.target.files[0]);
        event.target.value = null;

        CheckUploadReportFile(formData, getToken())
            .then((response) => {
                console.log(new Blob([response.data]).size);
                if (new Blob([response.data]).size > 0) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '工作紀綠表錯誤說明.xlsx');
                    document.body.appendChild(link);
                    link.click();
                } else {
                    alert('上傳完成');
                }

            }).catch(err => {
                console.log('err', err);
            })

    };

    return (
        <>

            <Typography variant="h4" gutterBottom>
                工作紀錄表明細
            </Typography>
            <Box>
                <Box sx={{ mb: 2, mt: 2 }}>
                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/Admin/WorkRecord"}>
                        <Button variant="contained" color="secondary" size='large' style={{ marginLeft: '8px' }} startIcon={<ArrowBackIcon />}>
                            回上一頁
                        </Button>
                    </Link>
                </Box>
                <Box>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: '90vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                sx={{
                                                    fontSize: "1rem",
                                                    fontWeight: 600,
                                                    backgroundColor: "rgb(20 19 19)",
                                                    color: "white"
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ListData
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {

                                                        if (column.id != "Edit") {
                                                            let value = "";
                                                            if (column.type == "datetime" && row[column.id] != null) {
                                                                value = moment(row[column.id]).format("YYYY-MM-DD HH:mm:ss");
                                                            } else if (column.type == "time" && row[column.id] != null) {
                                                                value = moment(row[column.id]).format("HH:mm:ss");
                                                            } else {
                                                                value = row[column.id];
                                                            }
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    sx={{
                                                                        fontSize: "1rem"
                                                                    }}
                                                                >
                                                                    {value}
                                                                </TableCell>
                                                            );
                                                        } else if (column.id == "Edit") {
                                                            const value = row["ReportDate"];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    sx={{
                                                                        fontSize: "1.1rem"
                                                                    }}
                                                                >
                                                                    <Link color="inherit" to={`/Admin/WorkRecord/Detail/${value}`}>
                                                                        <IconButton color="primary" component="span">
                                                                            <FormatListNumberedIcon />
                                                                        </IconButton>
                                                                    </Link>
                                                                </TableCell>
                                                            );
                                                        }

                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={paginationTotal}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>


            </Box>

        </>
    );
}