import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, IconButton, CircularProgress, Backdrop } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { getStaffGridList } from "../../API/api.js";
import moment from "moment"

const columns = [
    { id: 'StaffName', label: '人員名稱', minWidth: 70 },
    { id: 'StaffTypeName', label: '人員類別', minWidth: 70 },
    { id: 'IsShow', label: '是否顯示在日報EXCEL選單', minWidth: 50, type: 'bool' },
    { id: 'StaffNote', label: '備註', minWidth: 100 },
    { id: 'Creator', label: '新增人員', minWidth: 70 },
    { id: 'CreateDate', label: '新增日期', minWidth: 70, type: 'datetime' },
    { id: 'Modifier', label: '修改人員', minWidth: 70 },
    { id: 'ModifyDate', label: '修改日期', minWidth: 70, type: 'datetime' },
    { id: 'Edit', label: '編輯', minWidth: 20 }
];

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}))

export default function Index() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [paginationTotal, setPaginationTotal] = useState(0)
    const classes = useStyles();
    const [loadico, setloadico] = useState(false)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const [ListData, getListData] = useState([])
    const getList = (pagenumber, pagesize) => {
        setloadico(true);
        getStaffGridList({ pagenumber: pagenumber + 1, pagesize: pagesize })
            .then(res => {
                const valuesArray = res.data;
                getListData(valuesArray);
                setPaginationTotal(valuesArray[0].TOTAL_ROW_COUNT);
            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => setloadico(false))
    }



    useEffect(() => {
        getList(page, rowsPerPage);
    }, [page, rowsPerPage]);

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Typography variant="h4" gutterBottom>
                服務人員設定
            </Typography>
            <Box>
                <Box sx={{ mb: 2, mt: 2 }}>
                    <Link style={{ textDecoration: 'none', color: '#fff', margin: '20' }} to={`Add`}>
                        <Button sx={{ fontWeight: 600 }} variant="contained" size="large" startIcon={<AddCircleIcon />}>新增服務人員設定</Button>
                    </Link>
                </Box>
                <Box>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: '90vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                sx={{
                                                    fontSize: "1rem",
                                                    fontWeight: 600,
                                                    backgroundColor: "rgb(20 19 19)",
                                                    color: "white"
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ListData
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {

                                                        if (column.id != "Edit") {
                                                            let value = (column.type == "datetime" && row[column.id] != null) ? moment(row[column.id]).format("YYYY-MM-DD HH:mm:ss") : row[column.id];

                                                            value = (column.type == "bool" && row[column.id] != null) ? (row[column.id]==1?"是":"否") : row[column.id];

                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    sx={{
                                                                        fontSize: "1rem"
                                                                    }}
                                                                >
                                                                    {value}
                                                                </TableCell>
                                                            );
                                                        } else if (column.id == "Edit") {
                                                            const value = row["StaffName"];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    sx={{
                                                                        fontSize: "1.1rem"
                                                                    }}
                                                                >
                                                                    <Link color="inherit" to={`/Admin/Staff/Edit/${value}`}>
                                                                        <IconButton color="primary" component="span">
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </Link>
                                                                </TableCell>
                                                            );
                                                        }

                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[100,150,200]}
                            component="div"
                            count={paginationTotal}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>
            </Box>
        </>
    );
}