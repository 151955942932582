import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography, Box, Backdrop, CircularProgress, Stack, IconButton } from '@mui/material';
import moment from "moment"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { makeStyles } from '@material-ui/core/styles';


import { DownloadMonthReport, getAccountBookGridList, deleteWorkRecord } from "../../API/api.js";
import { getToken } from '../auth';

const columns = [
    { id: 'RecordMonth', label: '資料年月', minWidth: 70 },
    { id: 'DataCount', label: '該月已上傳天數', minWidth: 70 },
    { id: 'Download', label: '下載月報表', minWidth: 20 }
];

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));

export default function Index() {
    const [page, setPage] = useState(0);
    const [ListData, setListData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginationTotal, setPaginationTotal] = useState(0)
    const [loadico, setloadico] = useState(false);
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getList = (pagenumber, pagesize) => {
        setloadico(true);
        getAccountBookGridList({ pagenumber: pagenumber + 1, pagesize: pagesize })
            .then(res => {
                const valuesArray = res.data;
                setListData(valuesArray);
                setPaginationTotal(valuesArray[0].TOTAL_ROW_COUNT);
            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => setloadico(false))
    }


    useEffect(() => {
        getList(page, rowsPerPage);
    }, [page, rowsPerPage]);


    //const downloadReport = () => {
    //    DownloadReport(getToken()).then((response) => {
    //        const yyyyMMdd = moment().format('YYYYMMDD');
    //        const url = window.URL.createObjectURL(new Blob([response.data]));
    //        const link = document.createElement('a');
    //        link.href = url;
    //        link.setAttribute('download', yyyyMMdd + '工作紀錄表.xlsx'); //or any other extension
    //        document.body.appendChild(link);
    //        link.click();


    //    });
    //}

    const downloadMonthReport = (YearMonth) => {
       
        if (YearMonth) {
            setloadico(true);
            DownloadMonthReport(getToken(), YearMonth).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', YearMonth + '月報資料.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();


            }).finally(() => setloadico(false));

           
      
        }
    }

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>

            <Typography variant="h4" gutterBottom>
                財務帳冊及月報表管理
            </Typography>
            <Box>
                <Box sx={{ mb: 2, mt: 2 }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        
                    </Stack>
                </Box>
                <Box>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: '90vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                sx={{
                                                    fontSize: "1rem",
                                                    fontWeight: 600,
                                                    backgroundColor: "rgb(20 19 19)",
                                                    color: "white"
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ListData
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {

                                                        if (column.id != "Download") {
                                                            const value = (column.type == "datetime" && row[column.id] != null) ? moment(row[column.id]).format("YYYY-MM-DD HH:mm:ss") : row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    sx={{
                                                                        fontSize: "1rem"
                                                                    }}
                                                                >
                                                                    {value}
                                                                </TableCell>
                                                            );
                                                        } else if (column.id == "Download") {
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    sx={{
                                                                        fontSize: "1.1rem"
                                                                    }}
                                                                >
                                                                    <IconButton color="primary" component="span" onClick={() => downloadMonthReport(row["RecordMonth"])}>
                                                                        <CloudDownloadIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            );
                                                        } 

                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={paginationTotal}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>


            </Box>

        </>
    );
}