import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Typography, Box, Button, Stack, IconButton } from '@mui/material';
import moment from "moment"
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UploadIcon from '@mui/icons-material/Upload';

import { DownloadReport, CheckUploadReportFile, getWorkRecordGridList, deleteWorkRecord } from "../../API/api.js";
import { getToken } from '../auth';

const columns = [
    { id: 'ReportDate', label: '報表日期', minWidth: 200 },
    { id: 'DataCount', label: '資料筆數', minWidth: 200 },
    { id: 'Creator', label: '上傳人員', minWidth: 200 },
    { id: 'CreateDate', label: '上傳時間', minWidth: 200, type: 'datetime' },
    { id: 'Detail', label: '明細', minWidth: 20 },
    { id: 'Delete', label: '刪除', minWidth: 20 }
];

export default function Index() {
    const [page, setPage] = useState(0);
    const [ListData, setListData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [paginationTotal, setPaginationTotal] = useState(0)
    const [loadico, setloadico] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getList = (pagenumber, pagesize) => {
        setloadico(true);
        getWorkRecordGridList({ pagenumber: pagenumber + 1, pagesize: pagesize })
            .then(res => {
                const valuesArray = res.data;
                setListData(valuesArray);
                setPaginationTotal(valuesArray[0].TOTAL_ROW_COUNT);
            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => setloadico(false))
    }

    const Input = styled('input')({
        display: 'none',
    });



    useEffect(() => {
        getList(page, rowsPerPage);
    }, [page, rowsPerPage]);


    const downloadReport = () => {
        DownloadReport(getToken()).then((response) => {
            const yyyyMMdd = moment().format('YYYYMMDD');
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', yyyyMMdd+'工作紀錄表.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();

         
        });
    }

    const uploadReport = (event) => {
        
        const formData = new FormData();
        formData.append('File1', event.target.files[0]);
        event.target.value = null;

        CheckUploadReportFile(formData, getToken())
            .then((response) => {
                console.log(new Blob([response.data]).size);
                if (new Blob([response.data]).size > 0) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', '工作紀綠表錯誤說明.xlsx');
                    document.body.appendChild(link);
                    link.click();
                } else {
                    alert('上傳完成');
                    getList(page, rowsPerPage);
                }

            }).catch(err => {
                console.log('err', err);
            })
            
    };

    const delWorkRecord = (ReportDate) => {
        var yes = window.confirm('你確定刪除' + ReportDate + '資料？');

        if (yes) {
            if (ReportDate) {
                setloadico(true);

                var para = new Object();
                para["ReportDate"] = ReportDate;

                deleteWorkRecord(para , getToken())
                    .then(res => {
                        if (res.data) {
                            getList(page, rowsPerPage);
                        }
                    })
                    .catch(err => {
                        console.log('err', err);
                    })
                    .finally(() => setloadico(false))
            }
        }
    }

    return (
        <>
            
            <Typography variant="h4" gutterBottom>
                工作紀錄管理
            </Typography>
            <Box>
                <Box sx={{ mb: 2, mt: 2 }}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <Button onClick={downloadReport} variant="contained" size="large" startIcon={<CloudDownloadIcon />}>下載當日工作紀錄報表</Button>
                        <label htmlFor="contained-button-file">
                            <Input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="contained-button-file" onChange={uploadReport} type="file" />
                            <Button variant="contained" component="span" color="secondary" size="large" startIcon={<UploadIcon />}>
                                上傳工作紀錄報表
                            </Button>
                        </label>
                    </Stack>
                </Box>
                <Box>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: '90vh' }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                sx={{
                                                    fontSize: "1rem",
                                                    fontWeight: 600,
                                                    backgroundColor: "rgb(20 19 19)",
                                                    color: "white"
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ListData
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {

                                                        if (column.id != "Detail" && column.id != "Delete") {
                                                            const value = (column.type == "datetime" && row[column.id] != null) ? moment(row[column.id]).format("YYYY-MM-DD HH:mm:ss") : row[column.id];
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    sx={{
                                                                        fontSize: "1rem"
                                                                    }}
                                                                >
                                                                    {value}
                                                                </TableCell>
                                                            );
                                                        } else if (column.id == "Detail") {
                                                            const value = moment(row["ReportDate"]).format("YYYY-MM-DD")
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    sx={{
                                                                        fontSize: "1.1rem"
                                                                    }}
                                                                >
                                                                    <Link color="inherit" to={`/Admin/WorkRecord/Detail/${value}`}>
                                                                        <IconButton color="primary" component="span">
                                                                            <FormatListNumberedIcon />
                                                                        </IconButton>
                                                                    </Link>
                                                                </TableCell>
                                                            );
                                                        } else if (column.id == "Delete") {
                                                            const value = moment(row["ReportDate"]).format("YYYY-MM-DD")
                                                            return (
                                                                <TableCell key={column.id} align={column.align}
                                                                    sx={{
                                                                        fontSize: "1.1rem"
                                                                    }}
                                                                >
                                                                    <IconButton color="primary" component="span" onClick={() => delWorkRecord(value)} >
                                                                        <DeleteForeverIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            );
                                                        }

                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[100, 150, 200]}
                            component="div"
                            count={paginationTotal}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Box>


            </Box>
          
        </>
    );
}