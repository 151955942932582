import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { pink } from '@material-ui/core/colors';

import { Login } from "../API/api.js";

import { RemoveToken, IsLogin } from './auth';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <a color="inherit" href="https://www.RegentSPA.com.tw/">
                Regent SPA Web | 晶華首席上乘舒壓經典
      </a>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: pink[500],
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const classes = useStyles();

    const navigate  = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleUsername = (e) => {
        setUsername(e.target.value);
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username === "") {
           alert('請輸入使用者名稱');
        }

        if (password === "") {
            alert('請輸入使用者密碼');
        }

        Login({ Username: username, Password: password })
            .then(res => {

                if (res.data.Token != undefined) {
                    localStorage.setItem('RegentSPAToken', res.data.Token);
                    navigate("/Admin/");
                }
             
            })
            .catch(err => {
                navigate("/Login");
                console.log(err);
                alert('登入失敗。請輸入正確名稱及密碼');
            })

    };

   

    useEffect(() => {
        if (IsLogin()) {
            navigate("/Admin/");
        } else {

        }
    });

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar} style={{ backgroundColor: pink[500]}}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h4" variant="h4">
                    晶華首席後台管理
                </Typography>
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                     
                        required
                        fullWidth
                        id="userName"
                        label="使用者名稱"
                        name="userName"
                        autoComplete="userName"
                        value={username}
                        onChange={handleUsername} 
                        autoFocus
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                       
                        required
                        fullWidth
                        name="password"
                        label="使用者密碼"
                        type="password"
                        id="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={handlePassword}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        登入
                     </Button>
                  
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}