import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/List';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@mui/icons-material/Work';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ListSubheader from '@mui/material/ListSubheader';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import Divider from '@material-ui/core/Divider';
import {
    Link
} from "react-router-dom";

export const mainListItems = (

    <div>
        <ListItem button component={Link} to={`Dashboard`}>
            <ListItemIcon>
                <WorkIcon />
            </ListItemIcon>
            <ListItemText primary="首頁" />
        </ListItem>
        <ListSubheader color="primary" inset>基本資料設定</ListSubheader>
        <Divider />
        <ListItem button component={Link} to={`StaffType`}>
            <ListItemIcon>
                <WorkIcon />
            </ListItemIcon>
            <ListItemText primary="服務人員類別設定" />
        </ListItem>
        <ListItem button component={Link} to={`Staff`}>
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="服務人員設定" />
        </ListItem>
        <ListItem button component={Link} to={`ServiceItem`}>
            <ListItemIcon>
                <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="服務項目設定" />
        </ListItem>
        <ListSubheader color="primary" inset>報表及財務管理</ListSubheader>
        <Divider />
        <ListItem button component={Link} to={`WorkRecord`}>
            <ListItemIcon>
                <ListIcon />
            </ListItemIcon>
            <ListItemText primary="工作紀錄管理" />
        </ListItem>
        <ListItem button component={Link} to={`AccountBook`}>
            <ListItemIcon>
                <PriceChangeIcon />
            </ListItemIcon>
            <ListItemText primary="財務帳冊及月報表管理" />
        </ListItem>
    </div>
);