import React, { useState, useEffect } from 'react';
import { Grid, Typography, Backdrop, TextField, CircularProgress, Box, Button, MenuItem, Select, FormHelperText, FormControl } from '@mui/material';
import { useNavigate } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { getToken, IsLogin } from '../auth';
import { Link } from 'react-router-dom';

import { getStaffTypeList, addServiceItem, checkServiceItemExist } from "../../API/api.js";


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
}));


export default function Add() {
    const [loadico, setloadico] = useState(false)
    const classes = useStyles();
    const navigate = useNavigate();

    const [values, setValues] = useState({
        ServiceItemName: '',
        ServiceItemTime: 30,
        ServiceUnit: 0,
        StaffType: 0,
        ServiceFee: 0,
        ServiceNote: '',
        ServiceEnabled: 1
    });

    const handleServiceItemName = event => {
        setValues({ ...values, ServiceItemName: event.target.value });
    };

    const handleServiceItemTime = event => {
        setValues({ ...values, ServiceItemTime: event.target.value });
    };

    const handleServiceUnit = event => {
        setValues({ ...values, ServiceUnit: event.target.value });
    };

    const handleStaffTypeID = event => {
        setValues({ ...values, StaffType: event.target.value });
    };

    const handleServiceFee = event => {
        setValues({ ...values, ServiceFee: event.target.value });
    };

    const handleServiceNote = event => {
        setValues({ ...values, ServiceNote: event.target.value });
    };

    const handleServiceEnabled = event => {
        setValues({ ...values, ServiceEnabled: event.target.value });
    };


    const [StaffTypeListData, setStaffTypeListData] = useState([])
    const getList = () => {

        getStaffTypeList()
            .then(res => {
                const valuesArray = res.data;
                setStaffTypeListData(valuesArray);

            })
            .catch(err => {
                console.log('err', err);
            })
    }



    const submit = e => {

        if (!IsLogin()) {
            alert('登入時間過期請重新登入');
            return;
        }

        e.preventDefault();

        if (values.ServiceItemName.length > 0) {
            

            checkServiceItemExist(values.ServiceItemName)
                .then(res => {
                    if (res.data) {
                        alert('服務項目已存在');
                    } else {
                        setloadico(true);

                        addServiceItem(values, getToken())
                            .then(res => {
                                if (res.data) {
                                    navigate('/Admin/ServiceItem');
                                }
                            })
                            .catch(err => {
                                console.log('err', err);
                            })
                            .finally(() => setloadico(false));
                    }
                })
                .catch(err => {
                    setloadico(false)
                    console.log('err', err);
                })

          
        }

    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            <Backdrop open={loadico} className={classes.backdrop}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <Typography variant="h4" gutterBottom>
                新增服務項目
            </Typography>
            <Box>
                <form autoComplete="off" onSubmit={submit}>
                    <Typography variant="h6" gutterBottom>
                        請輸入下列資訊
                    </Typography>
                    <Box sx={{ m: 3 }}>
                        <Grid container spacing={3}>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    required
                                    id="ServiceItemName"
                                    name="ServiceItemName"
                                    label="服務項目"
                                    onChange={handleServiceItemName}
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    required
                                    id="ServiceItemTime"
                                    name="ServiceItemTime"
                                    label="服務時間分鐘/次數 (1代表依次數1次) "
                                    onChange={handleServiceItemTime}
                                    fullWidth
                                    variant="standard"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    required
                                    id="ServiceUnit"
                                    name="ServiceUnit"
                                    label="單位節數 (0代表不算節數) "
                                    onChange={handleServiceUnit}
                                    fullWidth
                                    variant="standard"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <TextField
                                    required
                                    id="ServiceFee"
                                    name="ServiceFee"
                                    label="費用"
                                    onChange={handleServiceFee}
                                    fullWidth
                                    variant="standard"
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item sm={12} md={12}>
                                <FormControl required>
                                    <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>選擇服務人員類別：</FormHelperText>
                                    <Select
                                        value={values.StaffTypeID}
                                        onChange={handleStaffTypeID}
                                    >
                                        <MenuItem key={-1} value={0}>{""}</MenuItem>
                                        {
                                            
                                            StaffTypeListData.map(
                                                (childItem, chiIndex) => {
                                                    return (
                                                        <MenuItem key={chiIndex} value={childItem.Id}>{childItem.StaffTypeName}</MenuItem>
                                                    )
                                                }
                                            )
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item sm={12} md={12}>
                                <FormHelperText style={{ fontSize: '16px', color: 'Black', fontWeight: 'bold' }}>是否啟用：</FormHelperText>
                                <FormControl>

                                    <Select
                                        value={values.ServiceEnabled}
                                        onChange={handleServiceEnabled}
                                    >
                                        <MenuItem value={1}>啟用</MenuItem>
                                        <MenuItem value={0}>停用</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item sm={12} md={12}>
                                <TextField
                                    required
                                    id="ServiceNote"
                                    name="ServiceNote"
                                    label="備註"
                                    onChange={handleServiceNote}
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="standard"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" size='large' type="submit" startIcon={<SaveIcon />}>
                            儲存
                        </Button>

                        <Link style={{ textDecoration: 'none', color: '#fff' }} to={"/Admin/ServiceItem"}>
                            <Button variant="contained" color="secondary" size='large' style={{ marginLeft: '8px' }} startIcon={<CancelIcon />}>
                                取消
                            </Button>
                        </Link>
                    </Box>
                </form>

            </Box>
        </>
    );
}